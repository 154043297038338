import "./index.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "react-loaders";
import AnimatedLetters from "../AnimatedLetters";
import LogoTitle from "../../assets/images/yo(1).png";

const Home = () => {
  const [letterClass, setLetterClass] = useState("text-animate");

  let fnameArray = [];
  const fname = "Hamish";
  for (let i = 0; i < fname.length; i++) {
    fnameArray.push(fname[i]);
  }
  let nameArray = [];
  const name = "Sandys-Renton";
  for (let i = 0; i < name.length; i++) {
    nameArray.push(name[i]);
  }

  let jobArray = [];
  const job = "Cloud Architect";
  for (let i = 0; i < job.length; i++) {
    jobArray.push(job[i]);
  }
  return (
    <div className="container home-page">
      <div className="text-zone">
        <h1>
          <AnimatedLetters
            letterClass={letterClass}
            strArray={fnameArray}
            idx={15}
          />
          <br />
          <AnimatedLetters
            letterClass={letterClass}
            strArray={nameArray}
            idx={5}
          />
          <br />
          <AnimatedLetters
            letterClass={letterClass}
            strArray={jobArray}
            idx={20}
          />
        </h1>
        <h2>AWS Cloud Architect | Frontend Developer | Generative Artist</h2>
        <a
          href="mailto:hmsrenton@gmail.com?subject=Via Hamish.ninja"
          className="flat-button"
        >
          Email me
        </a>
      </div>
    </div>
  );
};
export default Home;
