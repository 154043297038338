import "./index.scss";
import AnimatedLetters from "../AnimatedLetters";
const About = () => {
  let aboutArray = [];
  const about = "About Me";
  for (let i = 0; i < about.length; i++) {
    aboutArray.push(about[i]);
  }
  return (
    <div className="container about-page">
      <div className="text-zone">
        <h1>
          <AnimatedLetters strArray={aboutArray} idx={5} />
        </h1>
        <p>Master of Information Technology</p>
        <p>Certified AWS Certified Cloud Practitioner</p>
        <h2>Skills and Experience:</h2>
        <h3>Cloud Technology:</h3>
        <p>
          Amplify, Route53, Cloud9, CodeCommit, EC2, S3, VPC's, IAM
          Organizations, Docker
        </p>
        <h3>Scripting & Programming:</h3>
        <p>
          Bash Linux Command Line, Javascript, Typescript, Python, Node, Java
          OOP, Processing, PHP, HTML, CSS
        </p>
        <h3>Data Management:</h3>
        <p>MySQL, MongoDB</p>
        <h3>Frameworks:</h3>
        <p>React, Vue</p>
        <h3>CICD:</h3>
        <p>GitHub Actions, Git, AWS Amplify</p>
        <h3>Agile:</h3>
        <p>SCRUM, JIRA, Confluence, Trello</p>
        <h3>UI:</h3>
        <p>Figma, Adobe X, Balsamiq</p>
      </div>
    </div>
  );
};

export default About;
