// Sidebar.jsx

import "./index.scss";

import LogoNinja from "../../assets/images/simpleninja.jpeg";
import HamishNinja from "../../assets/images/Hamishninja.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, NavLink } from "react-router-dom";
import {
  faHome,
  faUser,
  faEnvelope,
  faSuitcase,
  faBars,
  faClose,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
const Sidebar = () => {
  return (
    <div className="nav-bar">
      <Link className="logo">
        <img className="logo-ninja" src={LogoNinja} alt="ninja logo" />
        <img
          className="logo-text"
          src={HamishNinja}
          alt="Hamish.ninja text svg"
        />
      </Link>
      <nav>
        <NavLink exact="true" activeclassname="active" to="/">
          <FontAwesomeIcon icon={faHome} color="#4d4d4e" />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="about-link"
          to="/about"
        >
          <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="portfolio-link"
          to="/portfolio"
        >
          <FontAwesomeIcon icon={faBriefcase} color="#4d4d4e" />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="contact-link"
          to="mailto:hmsrenton@gmail.com?subject=Via Hamish.ninja"
        >
          <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e" />
        </NavLink>
        <NavLink
          exact="true"
          target="_blank"
          rel="noreferrer"
          activeclassname="active"
          className="linkedin-link"
          to="https://www.linkedin.com/in/hamish-sandys-renton-793671246/"
        >
          <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" />
        </NavLink>
        <NavLink
          exact="true"
          target="_blank"
          rel="noreferrer"
          activeclassname="active"
          className="github-link"
          to="https://github.com/Hmsr"
        >
          <FontAwesomeIcon icon={faGithub} color="#4d4d4e" />
        </NavLink>
      </nav>
      {/* <ul>
        <li>
          <a
            href="https://www.linkedin.com/in/hamish-sandys-renton-793671246/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} color="white" />
          </a>
        </li>
      </ul> */}
    </div>
  );
};

export default Sidebar;
